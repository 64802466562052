import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import AboutUsSec from '@motokiki/shared/aboutUs';
import HomepageBanner from '@motokiki/shared/components/HomepageBannerRebrand';
import BrandSec from '@motokiki/shared/brandSec';
import LocalFitterSec from '@motokiki/shared/localFitterSec';
import HeroHomeSec from '../components/HeroHomeSec';
import SEO from '@/components/SEO/Seo';

import '../styles/main.scss';


interface Props {
  data: {
    strapi: {
      homepage: {
        AboutUsSection: {
          headerText: string;
          paragraph1: string;
          paragraph2: string;
          image: {
            url: string;
          };
        };
        BrandsSection: {
          headerText: string;
          subText: string;
          buttonText: string;
          image1: {
            url: string;
          };
          image2: {
            url: string;
          };
          image3: {
            url: string;
          };
          image4: {
            url: string;
          };
          image5: {
            url: string;
          };
          image6: {
            url: string;
          };
          image7: {
            url: string;
          };
          image8: {
            url: string;
          };
        };
        LocalFitterSection: {
          buttonText: string;
          initialText: string;
          secondText: string;
          subParagraph: string;
          mainImg: {
            url: string;
          };
        };
      };
    };
  };
}

const App = ({ data }: Props): ReactElement => {
  const {
    AboutUsSection,
    BrandsSection,
    LocalFitterSection,
  } = data.strapi.homepage;

  return (
    <>
    <SEO/>
      <Layout>
        <div>
          <HeroHomeSec />
          <HomepageBanner />
          <BrandSec data={BrandsSection} />
          <LocalFitterSec data={LocalFitterSection} />
          <AboutUsSec data={AboutUsSection} />
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query HomePage {
    strapi {
      homepage(publicationState: PREVIEW) {
        AboutUsSection {
          headerText
          paragraph1
          paragraph2
          image {
            url
          }
        }
        BrandsSection {
          headerText
          subText
          buttonText
          image1 {
            url
          }
          image2 {
            url
          }
          image3 {
            url
          }
          image4 {
            url
          }
          image5 {
            url
          }
          image6 {
            url
          }
          image7 {
            url
          }
          image8 {
            url
          }
        }
        LocalFitterSection {
          buttonText
          initialText
          secondText
          subParagraph
          mainImg {
            url
          }
        }
      }
    }
  }
`;

export default App;
